<template>
  <label
    :for="name"
    class="relative font-medium text-gray-900"
    :class="variantSize()"
  >
    <span>
      {{ text }}
    </span>
    <span v-if="isRequired" class="absolute text-cred pl-0.5"> *</span>
  </label>
</template>

<script setup lang="ts">
import { Size } from '@/types/enums'

const props = defineProps({
  isRequired: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    default: null,
  },
  size: {
    type: String as PropType<Size>,
    default: Size.sm,
  },
})

function variantSize() {
  switch (props.size) {
    case Size.sm:
      return ['block text-sm']
    case Size.md:
      return ['text-base']
  }
}
</script>
